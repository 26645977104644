<template>
  <div class="talk-code">
    <van-search
      v-model="searchValue"
      placeholder="输入二维码标题搜索"
      @search="onRefresh"
      @clear="clearSearch" 
    />
    <p class="tip">共计{{totalRecords}}条数据</p>
    <div class="talk-list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-if="personList.length > 0" v-model="loading" :finished="finished" finished-text="没有更多了" @load="getTalkCodeList" :offset="100">
          <div v-for="(item, index) of personList" :key="index">
            <div class="talk-list-item">
              <div class="item-head" @click="previewImage(item.qrUrl)">
                <svg class="icon icon-code" aria-hidden="true">
                  <use xlink:href="#iconwode_tuokema"></use>
                </svg>
                {{item.qrName}}
              </div>
              <div class="item-num">
                <div class="num">
                  <span>{{item.todayNumber||0}}</span>
                  <em>今日添加</em>
                </div>
                <div class="num">
                  <span>{{item.weekNumber||0}}</span>
                  <em>本周添加</em>
                </div>
                <div class="num">
                  <span>{{item.totalNumber||0}}</span>
                  <em>添加总人数</em>
                </div>
              </div>
              <div class="item-foot">
                <div class="foot-left" >
                  <template v-if="item.clueId">
                    <img :src="item.clueAvatar" alt="">
                    {{item.clueName}}
                  </template>
                  <span class="red" v-else>未绑定拓客人</span>
                </div>
                <div class="foot-right">
                  <span class="btn" @click="edit(item)">编辑</span>
                  <span class="btn" @click="openDetail(item.tkId)">查看明细</span>
                  <span class="btn" v-if="!item.clueId" @click="addPerson(item.tkId)">绑定拓客人</span>
                </div>
              </div>
            </div>
          </div>
        </van-list>
        <wxb-no-data v-else />
      </van-pull-refresh>
    </div>
    <!-- 拓客明细 -->
    <talkCodeDetail  :tkId="tkId" :talkCodeDetailShow = "talkCodeDetailShow" @talkCodeDetailClose="talkCodeDetailClose"></talkCodeDetail>  
    <!-- 绑定拓客人 -->
    <addPersonDialog 
      :tkId="tkId" 
      :addPersonDialogShow="addPersonDialogShow" 
      @refreshPage="onRefresh"
      @addPersonDialogClose="addPersonDialogClose">
    </addPersonDialog>
    <!-- 编辑拓客码 -->
    <editTalkCode 
      ref="editTalkCodeShow" 
      :estateId="estateId"
      @editTalkCodeClose="editTalkCodeClose" 
      @refreshPage="onRefresh">
    </editTalkCode> 
  </div>
</template>

<script>
import mine from '@/api/mine';
import addPersonDialog from './components/addPersonDialog.vue';
import editTalkCode from './components/editTalkCode.vue';
import talkCodeDetail from './components/talkCodeDetail.vue';
export default {
  name: 'addFriendsList',
  components: { addPersonDialog, editTalkCode, talkCodeDetail },
  data() {
    return {
      searchValue: '',
      talkCodeDetailShow: false,
      personList: [],
      addPersonDialogShow: false,
      pageNation: {
        pageNo: 1,
        pageSize: 10
      },
      totalRecords: '0',
      totalPages: 0,
      loading: false,
      finished: false,
      refreshing: false,
      tkId: '',
      qrCodeId: '',
      estateId: localStorage.getItem('platform_estateId')
    };
  },
  mounted() {
    this.getTalkCodeList();
  },
  methods: {
    async getTalkCodeList() {
      try {
        this.loading = true;
        let { data, code, msg } = await mine.getTalkCodeList({qrName: this.searchValue, pageNo: this.pageNation.pageNo, pageSize: this.pageNation.pageSize});
        this.loading = false;
        if (code !== '0') {
          this.finished = true;
          console.error('接口报错', msg);
          return;
        }
        if (this.pageNation.pageNo >= data.totalPages || data.totalPages === 0) {
          this.finished = true;
        } else {
          this.finished = false;
        }
        if (this.pageNation.pageNo === 1) {
          this.personList = data.data;
        } else {
          this.personList = this.personList.concat(data.data);
        }
        this.totalPages = data.totalPages;
        this.totalRecords = data.totalRecords;
        this.pageNation.pageNo++;
      } catch (err) {
        console.error('client overdue err', err);
        this.loading = false;
      }
    },
    //预览图片 展示二维码
    previewImage(img) {
      this.$wx.previewImage({
        current: img, // 当前显示图片的http链接
        urls: [img], // 需要预览的图片http链接列表
      });
    },
    //搜索清空
    clearSearch() {
      this.searchValue = '';
      this.pageNation.pageNo = 1;
      this.getTalkCodeList();
    },
    onRefresh() {
      this.pageNation.pageNo = 1;
      this.getTalkCodeList();
    },
    //绑定拓客人
    addPerson(tkId) {
      this.tkId = tkId;
      this.addPersonDialogShow = true;
    },
    addPersonDialogClose(flag) {
      console.log('flag----', flag);
      this.addPersonDialogShow = false;
    },
    //拓客明细
    openDetail(tkId) {
      console.log('id', tkId);
      this.tkId = tkId;
      this.talkCodeDetailShow = true;
    },
    talkCodeDetailClose() {
      this.talkCodeDetailShow = false;
    },
    edit(item) {
      this.$refs.editTalkCodeShow.init(item.tkId);
    },
    editTalkCodeClose() {
      this.editTalkCodeShow = false;
    }
  },
};
</script>

<style scoped lang="less">
.tip{
  font-size: 12px;
  font-weight: 400;
  color: @gray6;
  padding: 0 16px;
  box-sizing: border-box;
  margin: 12px 0px 0px 0px;
}
.talk-code{
  .van-search{
    padding: 6px 16px;
    .van-search__content{
      padding-left: 0;
      .van-cell{
        padding: 6px 16px;
        background: #F5F6F7;
        border-radius: 4px;
      }
    }
  }
  .talk-list-item{
    height: 158px;
    margin: 12px;
    background: #FFFFFF;
    border-radius: 4px;
    opacity: 1;
    padding: 0 12px;
    .item-head{
      border-bottom: 1px solid #EBEEF5;
      padding: 10px 0;
      color: @gray3;
      .icon-code{
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }
    }
    .item-num{
      display: flex;
      justify-content: space-between;
      padding: 14px 0;
      border-bottom: 1px solid #EBEEF5;
      .num{
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        span{
          color:  @gray3;
          font-size: 20px;
        }
        em{
          font-style: normal;
          color: @gray9;
          font-size: 12px;
          margin-top: 4px;
        }
      }
    }
    .item-foot{
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .foot-left{
        img{
          width: 24px;
          height: 24px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 6px;
        }
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #606266;
      }
      .red{
        color: @gary-wxb-error;  
        font-size: 12px;
      }
      .btn{
        display: inline-block;
        height: 28px;
        line-height: 28px;
        background: #F0F4FE;
        border-radius: 4px 4px 4px 4px;
        font-size: 12px;
        color: #3A74F2;
        padding: 0 8px;
        margin-left: 10px;
      }
    }
  }
}
  
</style>
